<template>
  <div class="h-100 position-lg-absolute">
    <h4 class="profile-divider with-divider blue f-w-400">
      {{ $t("profile.change_password") }}
    </h4>
    <form class="form h-100" @submit.prevent="handlePasswordChange">
      <be-form-error v-if="error" :message="error" />
      <div class="form-container h-100 overflow-auto">
        <div class="be-row">
          <div class="be-col">
            <div>
              <BaseInputPassword
                v-model="user.old_password"
                :errorMessage="oldPassErrorMessage"
                :isInvalid="
                  $v.user.old_password.$error ||
                    errors.old_password !== undefined
                "
                :label="$t('profile.actual_password')"
                :placeholder="$t('profile.insert_password_placeholder')"
                @blur="$v.user.old_password.$touch()"
              ></BaseInputPassword>
            </div>
          </div>
        </div>
        <div class="be-row">
          <div class="be-col">
            <div>
              <BaseInputPassword
                v-model="user.password"
                :errorMessage="newPassErrorMsg"
                :isInvalid="
                  $v.user.password.$error || errors.password !== undefined
                "
                :label="$t('profile.new_password')"
                :placeholder="$t('profile.insert_new_password_placeholder')"
                @blur="$v.user.password.$touch()"
              >
              </BaseInputPassword>
            </div>
          </div>
          <div class="be-col">
            <div>
              <BaseInputPassword
                v-model="user.password_confirmation"
                :errorMessage="confirmPassErrMsg"
                :isInvalid="$v.user.password_confirmation.$error"
                :label="$t('profile.confirm_new_password')"
                :placeholder="$t('profile.insert_confirm_password_placeholder')"
                @blur="$v.user.password_confirmation.$touch()"
              >
              </BaseInputPassword>
            </div>
          </div>
        </div>
      </div>
      <div class="button-container">
        <div class="divider"></div>
        <BaseButton
          :disabled="$v.$invalid || !editing"
          :loading="loading"
          :show-loading="true"
          buttonClass="btn-secondary"
        >
          {{ $t("profile.change_password") }}
        </BaseButton>
      </div>
    </form>
  </div>
</template>

<script>
import { minLength, required, sameAs } from "vuelidate/lib/validators";

export default {
  name: "FormPassword",
  computed: {
    oldPassErrorMessage() {
      if (this.$v.user.old_password.$error || this.errors.old_password) {
        if (!this.$v.user.password.required) {
          return this.$t("profile.required.actual_password");
        }
        if (this.errors.old_password) {
          return this.errors.old_password[0];
        }
      }
      return "";
    },
    newPassErrorMsg() {
      if (this.$v.user.password.$error || this.errors.password) {
        if (!this.$v.user.password.required) {
          return this.$t("profile.required.new_password");
        }
        if (!this.$v.user.password.minLength) {
          return this.$t("profile.required.password_length_6");
        }
        if (!this.$v.user.password.containsUppercase) {
          return this.$t("profile.required.at_least_one_uppercase");
        }
        if (!this.$v.user.password.containsNumber) {
          return this.$t("profile.required.at_least_one_number");
        }
        if (this.errors.password) {
          return this.errors.password[0];
        }
      }
      return "";
    },
    confirmPassErrMsg() {
      if (this.$v.user.password_confirmation.$error) {
        if (!this.$v.user.password_confirmation.required) {
          return this.$t("profile.confirm_new_password");
        }
        if (!this.$v.user.password_confirmation.sameAsPassword) {
          return this.$t("profile.password_not_matches");
        }
      }
      return "";
    },
  },
  watch: {
    user: {
      deep: true,
      handler() {
        this.editing = true;
      },
    },
  },

  data() {
    return {
      loading: false,
      editing: true,
      success: null,
      errors: [],
      error: null,
      user: {
        old_password: null,
        password: null,
        password_confirmation: null,
      },
    };
  },

  validations: {
    user: {
      old_password: { required },
      password: {
        required,
        minLength: minLength(6),
        containsUppercase: function(value) {
          return /[A-Z]/.test(value) === true;
        },
        containsNumber: function(value) {
          return /[0-9]/.test(value) === true;
        },
      },
      password_confirmation: { required, sameAsPassword: sameAs("password") },
    },
  },

  methods: {
    handlePasswordChange() {
      this.$v.$touch();
      if (!this.$v.invalid && !this.loading) {
        this.loading = true;
        this.success = null;
        this.errors = [];
        this.error = null;
        this.$store
          .dispatch("auth/changeUserPassword", this.user)
          .then(() => {
            this.success = this.$t("profile.password_successful_updated");
            this.editing = false;
            this.loading = false;
            this.user.password = null;
            this.user.password_confirmation = null;
            this.$v.$reset();
          })
          .catch(err => {
            if (err.response.data.errors)
              this.errors = err.response.data.errors;
            if (err.response.data.message)
              this.error = err.response.data.message;
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style scoped></style>
